/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { PrismicRichText } from '@prismicio/react'
import { theme } from '../../styles'
import Space from '../common/Space'
import Text from '../common/Text'
import Button from '../common/Button'
import TextLink from '../common/TextLink'
import AnimatedVideo from '../common/AnimatedVideo'
import VideoIframe from '../common/VideoIframe'
import getImage from '../../utils/getImage'
import ModalAnimated from '../common/modal/ModalAnimated'
import LottieAnimation from '../LottieAnimation'
import violetCheckMarkIcon from '../../../static/icons/check-icons/violet-check-icon.svg'

import { useUserLang } from '../../context/UserLangContext'
import generateAriaLabelTranslations from '../../data/generateAriaLabelTranslations'

const BG = styled.div`
  position: absolute;
  width: 500vw;
  margin-left: -50%;
  height: 100%;
  z-index: -1;
  background-color: ${p => (p.backgroundColor ? theme.color[p.backgroundColor] : theme.color.white)};
  margin-top: ${p => (p.hasTopPadding ? '-80px' : '0px')};

  @media (max-width: 1050px) {
    margin-top: ${p => (!p.hasBottomPadding && p.hasTopPadding ? '-56px' : '0px')};
  }
`

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${p => (!p.reverse ? 'row' : 'row-reverse')};
  justify-content: center;
  min-height: ${p => (p.hasPadding ? '488px' : 'unset')};
  width: calc(100% + 300px);
  margin-left: -150px;
  padding: 0px 150px;
  padding-top: ${p => (p.hasTopPadding ? '80px' : '0px')};
  padding-bottom: ${p => (p.hasBottomPadding ? '80px' : '0px')};
  position: relative;
  z-index: 1;

  @media (max-width: 1050px) {
    width: 100%;
    margin-left: 0px;
    flex-direction: column;
    padding-top: ${p => (p.hasTopPadding ? '56px' : '0px')};
    padding-bottom: ${p => (p.hasBottomPadding ? '56px' : '0px')};
    padding-left: 16px;
    padding-right: 16px;
    height: auto;
  }

  @media (max-width: 800px) {
    padding-left: 0px;
    padding-right: 0px;
  }

  .hide-desktop {
    display: none;
    * {
      text-align: center;
    }

    @media (max-width: 800px) {
      display: block;
    }
  }
  .hide-mobile {
    display: block;
    @media (max-width: 800px) {
      display: none;
    }
  }
`

const ImageWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Image = styled.img`
  display: flex;
  width: 100%;
  object-fit: contain;
  margin: 0 auto;

  @media (max-width: 1050px) {
    max-width: 500px;
    width: ${p => (p.hasVideo ? '80%' : '100%')};
  }

  @media (max-width: 800px) {
    width: ${p => (p.hasVideo ? '95%' : '100%')};
    max-width: unset;
  }
`

const LottieWrapper = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1050px) {
    max-width: 500px;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow-wrap: anywhere;
  max-width: 100%;

  ul {
    margin-left: 20px;
    li {
      text-align: left !important;
    }
  }

  @media (max-width: 1050px) {
    max-width: 700px;
    * {
      text-align: center !important;
      margin-left: auto;
      margin-right: auto;
    }
  }
`

const InfoOutWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`

const CheckMarkListWrapper = styled(Text)`
  ul {
    margin-block-start: 8px;
    margin-block-end: 8px;
  }
  li {
    padding-inline-start: 12px;
    list-style-type: none;
    list-style-image: url(${violetCheckMarkIcon});
  }

  @media (max-width: 800px) {
    li {
      margin-bottom: 16px;
    }
  }
`

const LinkWrapper = styled.div`
  @media (max-width: 800px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`

const Quote = styled.div`
  display: flex;
  gap: 24px;

  @media (max-width: 800px) {
    gap: 8px;
  }

  .gatsby-image-wrapper {
    width: unset;
    min-width: 72px;

    @media (max-width: 999px) {
      max-width: 64px;
    }
  }

  img {
    max-width: 72px;
    max-height: 72px;
    width: 72px;
    height: 72px;

    @media (max-width: 999px) {
      max-width: 64px;
      max-height: 64px;
      width: 64px;
      height: 64px;
    }
  }
`

const QuoteText = styled(Text)`
  text-align: left !important;
`

const Feature = ({ slice: { primary } }) => {
  const largeCapsText = primary.large_caps_text?.richText?.[0]?.text && (
    <PrismicRichText field={primary.large_caps_text.richText} />
  )
  const title = primary.title.richText?.[0]?.text && <PrismicRichText field={primary.title.richText} />
  const text = primary.text.richText?.[0]?.text && <PrismicRichText field={primary.text.richText} />
  const checkMarkList = primary.checkmark_list.richText?.[0]?.text && (
    <PrismicRichText field={primary.checkmark_list.richText} />
  )
  const subtitle = primary.subtitle.richText?.[0]?.text && <PrismicRichText field={primary.subtitle.richText} />
  const imageSrc = primary?.image.url
  const reverse = primary.is_revert
  const video = primary?.video.url
  const animatedVideo = primary?.animated_video.url
  const buttonText = primary?.button_text.text
  const buttonUrl = primary?.button_link.url
  const textLinkText = primary?.text_link_text.text
  const textLinkUrl = primary?.text_link.url
  const backgroundColor = primary.background_color
  const hasTopPadding = primary?.has_top_padding
  const hasBottomPadding = primary?.has_bottom_padding
  const anchorId = primary.anchor_id
  const quote = primary?.quote
  const quoteAuthor = primary.quote_author
  const quoteImage = primary.quote_image
  const rawLottie = primary.animation && primary.animation.text
  const lottieAnim = rawLottie ? JSON.parse(primary.animation.text) : null
  const lottieAriaLabel = primary?.animation_aria_label

  const [open, setOpen] = useState(false)

  const { userLang } = useUserLang()
  const languageData = generateAriaLabelTranslations({ userLang })

  return (
    <Wrapper
      id={anchorId || null}
      reverse={reverse}
      hasTopPadding={hasTopPadding === null ? true : hasTopPadding}
      hasBottomPadding={hasBottomPadding === null ? true : hasBottomPadding}
      backgroundColor={backgroundColor}
    >
      <div className="hide-desktop">
        {largeCapsText && (
          <Text font="largeCaps" richText color="purple500">
            {largeCapsText}
          </Text>
        )}
        {title && (
          <>
            <Text font="heading3" richText>
              {title}
            </Text>
            <Space height={16} mobileHeight={24} />
          </>
        )}
        {subtitle && (
          <>
            <Text font="bodyBold" richText>
              {subtitle}
            </Text>
            <Space height={16} />
          </>
        )}
      </div>
      {(imageSrc || lottieAnim) && !animatedVideo && (
        <ImageWrapper reverse={reverse}>
          {video ? (
            <a
              tabIndex="0"
              role="button"
              aria-label={languageData?.playVideo}
              onKeyDown={e => {
                if (e.key === 'Enter') setOpen(true)
              }}
              onClick={() => video && setOpen(true)}
              style={{ width: '100%' }}
            >
              {getImage(primary.image, Image, { hasVideo: video.length > 0 })}
            </a>
          ) : lottieAnim && typeof lottieAnim === 'object' ? (
            <LottieWrapper>
              <LottieAnimation lotti={lottieAnim} lottieAriaLabel={lottieAriaLabel} />
            </LottieWrapper>
          ) : (
            getImage(primary.image, Image)
          )}
        </ImageWrapper>
      )}
      {animatedVideo && <AnimatedVideo videoSrc={animatedVideo} poster={imageSrc} />}
      {(animatedVideo || imageSrc || video || lottieAnim) && <Space width={80} tabletHeight={24} />}
      <InfoOutWrapper tabindex="-1" reverse={reverse}>
        <InfoWrapper>
          <div className="hide-mobile">
            {largeCapsText && (
              <Text font="largeCaps" richText color="purple500">
                {largeCapsText}
              </Text>
            )}
            {title && (
              <>
                <Text font="heading3" richText>
                  {title}
                </Text>
                <Space height={16} />
              </>
            )}
            {subtitle && (
              <>
                <Text font="heading4" richText>
                  {subtitle}
                </Text>
                <Space height={16} />
              </>
            )}
          </div>
          {text && (
            <>
              <Text font="body" richText>
                {text}
              </Text>
              {checkMarkList ? <Space height={4} /> : <Space height={12} />}
            </>
          )}
          {checkMarkList && (
            <>
              <CheckMarkListWrapper richText>{checkMarkList}</CheckMarkListWrapper>
              <Space height={24} />
            </>
          )}
          {textLinkUrl && (
            <LinkWrapper>
              <TextLink text={textLinkText} to={textLinkUrl} />
            </LinkWrapper>
          )}
          {buttonUrl && !textLinkUrl && <Button text={buttonText} to={buttonUrl} />}
          {quote && quoteAuthor && quoteImage && (
            <>
              <Space height={64} mobileHeight={24} />
              <Quote>
                {getImage(quoteImage, Image)}
                <div>
                  <QuoteText smallBody>{`"${quote}"`}</QuoteText>
                  <Space height={8} mobileHeight={8} />
                  <QuoteText smallBodyBold>{quoteAuthor}</QuoteText>
                </div>
              </Quote>
            </>
          )}
        </InfoWrapper>
      </InfoOutWrapper>
      {video && (
        <ModalAnimated
          active={(video && open) === true}
          onClose={() => video && setOpen(false)}
          ariaLabel={languageData?.videoModal}
        >
          <VideoIframe isModal allowFullScreen videoSrc={video} autoPlay={false} />
        </ModalAnimated>
      )}
      <BG
        className="bg"
        backgroundColor={backgroundColor}
        hasTopPadding={hasTopPadding === null ? true : hasTopPadding}
        hasBottomPadding={hasBottomPadding === null ? true : hasBottomPadding}
      />
    </Wrapper>
  )
}

export default Feature

export const query = graphql`
  fragment Feature on PrismicPageDataBodyFeature {
    ...SliceBase
    primary {
      background_color
      has_top_padding
      has_bottom_padding
      anchor_id
      is_revert
      button_link {
        ...LinkContent
      }
      button_text {
        ...TextContent
      }
      text_link {
        ...LinkContent
      }
      text_link_text {
        ...TextContent
      }
      quote
      quote_author
      quote_image {
        alt
        url
        gatsbyImageData(width: 540)
      }
      video {
        url
      }
      animated_video {
        url
      }
      image {
        alt
        url
        gatsbyImageData(width: 540)
      }
      large_caps_text {
        ...TextContent
      }
      text {
        ...TextContent
      }
      checkmark_list {
        ...TextContent
      }
      subtitle {
        ...TextContent
      }
      title {
        ...TextContent
      }
      animation {
        ...TextContent
      }
      animation_aria_label
    }
  }

  fragment PostFeature on PrismicPostDataBodyFeature {
    ...SliceBase
    id
    primary {
      background_color
      has_top_padding
      has_bottom_padding
      anchor_id
      is_revert
      button_link {
        uid
        url
      }
      button_text {
        ...TextContent
      }
      text_link {
        uid
        url
      }
      text_link_text {
        ...TextContent
      }
      video {
        url
      }
      animated_video {
        url
      }

      image {
        alt
        url
        gatsbyImageData(width: 540)
      }
      text {
        ...TextContent
      }
      checkmark_list {
        ...TextContent
      }
      subtitle {
        ...TextContent
      }
      title {
        ...TextContent
      }
      animation {
        ...TextContent
      }
      animation_aria_label
    }
  }
`
